<template>
<img src="../../assets/logo-branca.png" alt="Geração de Negócios no Segmento de Óleo & Gáso" :style="{height}"/>
</template>

<script>
export default {
  name: "AppLogo",
  props: ['height']
}
</script>

<style scoped>

</style>