import {MENUS} from "../menu";

export const ROUTER_NAMES = {
    OFERTAS: 'Oferta'
}

export default [
    {
        name: ROUTER_NAMES.OFERTAS,
        path: '/oferta',
        meta: {
            menu: MENUS.CADASTRO,
            title: 'Ofertas',
            searchAlias: 'Oferta',
            searchDescription: 'Veja todas as Oferta cadastradas',
            searchTerms: ['Oferta'],
            hasRole: 'role/oferta'
        },
        component: () => import('@/views/Oferta')
    },
]