import {MENUS} from "../menu";

export const ROUTER_NAMES = {
    PORTE: 'Porte'
}

export default [
    {
        name: ROUTER_NAMES.PORTE,
        path: '/porte',
        meta: {
            menu: MENUS.CADASTRO,
            title: 'Porte',
            searchAlias: 'Porte',
            searchDescription: 'Veja todos os Portes cadastrados',
            searchTerms: ['Porte'],
            hasRole: 'role/porte'
        },
        component: () => import('@/views/Porte')
    },
]