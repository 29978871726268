import {MENUS} from "../menu";

export const ROUTER_NAMES = {
    COMPRADOR: 'Comprador'
}

export default [
    {
        name: ROUTER_NAMES.COMPRADOR,
        path: '/comprador',
        meta: {
            menu: MENUS.CADASTRO,
            title: 'Compradores',
            searchAlias: 'Compradores',
            searchDescription: 'Veja todas os Compradores cadastrados',
            searchTerms: ['Comprador'],
            hasRole: 'role/comprador'
        },
        component: () => import('@/views/Comprador')
    },
]