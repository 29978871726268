import {MENUS} from "../menu";

export const ROUTER_NAMES = {
    RAMOATUACAO: 'Ramo de Atuação'
}

export default [
    {
        name: ROUTER_NAMES.RAMOATUACAO,
        path: '/ramo-atuacao',
        meta: {
            menu: MENUS.CADASTRO,
            title: 'Ramo de Atuação',
            searchAlias: 'Ramo de Atuação',
            searchDescription: 'Veja todas os Ramos de Atuação cadastrados',
            searchTerms: ['RamoAtuacao'],
            hasRole: 'role/ramoAtuacao'
        },
        component: () => import('@/views/RamoAtuacao')
    },
]