import {MENUS} from "../menu";

export const ROUTER_NAMES = {
    DEUMATCH: 'DeuMatch'
}

export default [
    {
        name: ROUTER_NAMES.DEUMATCH,
        path: '/deu-match',
        meta: {
            menu: MENUS.FUNCIONALIDAES,
            title: 'Matchs',
            searchAlias: 'Matchs',
            searchDescription: 'Veja todos os Matchs cadastrados',
            searchTerms: ['DeuMatch'],
            hasRole: 'role/deuMatch'
        },

        component: () => import('@/views/DeuMatch')
    },
]