import {
    doLoginAsComprador as doLoginAsCompradorApi,
    doLoginAsFornecedor as doLoginAsFornecedorApi,
    doLoginAsEmployee as doLoginAsEmployeeApi,
    getAllMenuPages
} from "../../api/auth";
import sortBy from "lodash.sortby";
import flatten from 'lodash.flatten';

export const LOGIN_MODES = {
    CLIENT: 'comprador',
    FORNECEDOR: 'fornecedor',
    EMPLOYEE: 'usuario'
}

export default {
    namespaced: true,
    state: {
        loginType: null,
        token: null,
        user: null,
        menu: null
    },
    mutations: {
        SET_TOKEN(state, token) {
            state.token = token;
        },
        SET_USER(state, user) {
            state.user = user;
        },
        SET_LOGIN_TYPE(state, type) {
            state.loginType = type;
        },
        SET_MENU(state, menu) {
            state.menu = menu
        }
    },
    getters: {
        user(state) {
            return state.user;
        },
        token(state) {
            return state.token;
        },
        loginType(state) {
            return state.loginType;
        },
        isAuthenticated(state) {
            return state.user != null && state.token != null && state.menu != null;
        },
        nivelUsuarioLogado(state) {
            let nivel = '';
            if (state.user != null && state.token != null && state.menu != null) nivel = state.user.nivel;
            return nivel;
        },
        menu(state) {
            return state.menu;
        },
        authorizedLinks(state) {
            return state.menu ? flatten(state.menu.map(({childrens}) => childrens.map(children => children.link))) : [];
        },
        routes(state) {
            return state.menu ? flatten(state.menu.map(menu => menu.childrens)) : [];
        }
    },
    actions: {
        doLoginAsComprador({commit}, {login, password}) {
            return doLoginAsCompradorApi(login, password).then(({token, current}) => {
                commit('SET_TOKEN', token);
                commit('SET_USER', current);
                commit('SET_LOGIN_TYPE', LOGIN_MODES.CLIENT);
            })
        },
        doLoginAsFornecedor({commit}, {login, password}) {
            return doLoginAsFornecedorApi(login, password).then(({token, current}) => {
                commit('SET_TOKEN', token);
                commit('SET_USER', current);
                commit('SET_LOGIN_TYPE', LOGIN_MODES.FORNECEDOR);
            })
        },
        doLoginAsEmployee({commit}, {login, password}) {
            return doLoginAsEmployeeApi(login, password).then(({token, current}) => {
                commit('SET_TOKEN', token);
                commit('SET_USER', current);
                commit('SET_LOGIN_TYPE', LOGIN_MODES.EMPLOYEE);
            })
        },
        async doLogin({dispatch, commit}, {type, login, password}) {
            console.log({type, login, password});
             try {
                 switch (type) {
                    case LOGIN_MODES.EMPLOYEE:
                        await dispatch('doLoginAsEmployee', {login, password});
                        break;
                    case LOGIN_MODES.FORNECEDOR:
                        await dispatch('doLoginAsFornecedor', {login, password});
                        break;
                    default:
                        await dispatch('doLoginAsComprador',  {login, password});
                        break;
                 }

                 const menu = await getAllMenuPages()
                     .then(menus => {
                         return sortBy(menus.map(menu => ({
                             icon: menu.icon,
                             title: menu.titulo,
                             order: menu.ordem,
                             link: menu.url,
                             childrens: sortBy(menu.filhos.filter(item => item.url !== '').map(children => ({
                                 title: children.titulo,
                                 order: children.ordem,
                                 link: children.url,
                                 icon: children.icon,
                                 searchTerms: [menu.titulo, ...children.titulo.split(' ')],
                                 searchDescription: `${menu.titulo} de ${children.titulo}`,
                                 searchable: true
                             })),['order'])
                         })).filter(item => item.childrens.length > 0),['order']);

                     });
                 //console.log(menu);
                 commit('SET_MENU', menu);
             } catch (err) {
                 await dispatch('doLogout');
                 throw err;
             }
        },
        getDefaultHomePage({getters}) {
            if(getters.loginType === LOGIN_MODES.CLIENT) {
                return 'Painel'
            } else {
                return 'Painel'
            }
        },
        getDefaultLoginPage({getters}) {
            return 'Login'
            
        },
        doLogout({commit}) {
            commit('SET_TOKEN', null);
            commit('SET_USER', null);
            commit('SET_MENU', null);
        }
    }
}