import {MENUS} from "../menu";

export const ROUTER_NAMES = {
    ATIVIDADEECONOMICA: 'Atividade Econômica'
}

export default [
    {
        name: ROUTER_NAMES.ATIVIDADEECONOMICA,
        path: '/atividade-economica',
        meta: {
            menu: MENUS.CADASTRO,
            title: 'Atividade Econômica',
            searchAlias: 'Atividade Econômica',
            searchDescription: 'Veja todas as Atividades Econômicas cadastradas',
            searchTerms: ['AtividadeEconomica'],
            hasRole: 'role/atividadeEconomica'
        },
        component: () => import('@/views/AtividadeEconomica')
    },
]