import {MENUS} from "../menu";

export const ROUTER_NAMES = {
    DEMANDA: 'Demanda'
}

export default [
    {
        name: ROUTER_NAMES.DEMANDA,
        path: '/demanda',
        meta: {
            menu: MENUS.CADASTRO,
            title: 'Demandas',
            searchAlias: 'Demandas',
            searchDescription: 'Veja todas os Demandas cadastrados',
            searchTerms: ['Demanda'],
            hasRole: 'role/demanda'
        },
        component: () => import('@/views/Demanda')
    },
]