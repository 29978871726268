import {MENUS} from "../menu";

export const ROUTER_NAMES = {
    SETOR: 'Setor'
}

export default [
    {
        name: ROUTER_NAMES.SETOR,
        path: '/setor',
        meta: {
            menu: MENUS.CADASTRO,
            title: 'Setor',
            searchAlias: 'Setor',
            searchDescription: 'Veja todos os Setores cadastrados',
            searchTerms: ['Setor'],
            hasRole: 'role/setor'
        },
        component: () => import('@/views/Setor')
    },
]