import {MENUS} from "../menu";

export const ROUTER_NAMES = {
    OFERTAFORNECEDOR: 'OfertaFornecedor'
}

export default [
    {
        name: ROUTER_NAMES.OFERTAFORNECEDOR,
        path: '/ofertas-fornecedor',
        meta: {
            menu: MENUS.FUNCIONALIDAES,
            title: 'Ofertas por Fornecedor',
            searchAlias: 'OfertaFornecedor',
            searchDescription: 'Veja todas as Ofertas por Fornecedor cadastradas',
            searchTerms: ['Oferta','Fornecedor'],
            hasRole: 'role/ofertaFornecedor'
        },
        component: () => import('@/views/OfertaFornecedor')
    },
]