import {MENUS} from "../menu";

export const ROUTER_NAMES = {
    FORNECEDOR: 'Fornecedor'
}

export default [
    {
        name: ROUTER_NAMES.FORNECEDOR,
        path: '/fornecedor',
        meta: {
            menu: MENUS.CADASTRO,
            title: 'Fornecedores',
            searchAlias: 'Fornecedores',
            searchDescription: 'Veja todas os Fornecedores cadastrados',
            searchTerms: ['Fornecedor'],
            hasRole: 'role/fornecedor'
        },
        component: () => import('@/views/Fornecedor')
    },
]